import { Connector, CheckoutEvents } from "./connector";

export type * from "./connector";
export { setConnectorBuilder } from "./connector";

export { BrowserLanguage, LanguageCode, TranslationHint, Price, Count, GermanTranslator, EnglishTranslator, Translator } from "./translations";
export { unchanging } from "./utils/signals";

export { default as Shop, type ShopConnector, type ShopOptions } from "./api";
export { Cart } from "./cart";
export { type Event, type EventPriceInformation, type Category, type Discount } from "./event";
export { CouponApplicationFailed } from "./errors";
export { Bundle } from "./bundles";
export { Booking } from "./booking";
export { type Wizard } from "./wizards";


export function buildProxy(connector: Connector, target: Window = window, error: (reason: any) => void = console.error): () => void {
    const signal = new AbortController();

    (async () => {
        const { buildProxy } = await import("./iframe/server");
        const proxy = buildProxy(connector);
        proxy.runForever(target, null, signal.signal);
    })().catch(error);

    return () => signal.abort();
}

export async function buildProxyConnector(target: Window, origin: string, abort?: AbortSignal): Promise<Connector> {
    const { buildProxyConnector } = await import("./iframe/client");
    return await buildProxyConnector(target, origin, abort);
}

export type { RPCServer } from "./iframe/server";
export type { RPCClient } from "./iframe/client";

export async function createRPCServer(...params: ConstructorParameters<typeof import("./iframe/server").RPCServer>): Promise<import("./iframe/server").RPCServer> {
    const { RPCServer } = await import("./iframe/server");
    return new RPCServer(...params);
}

export async function createRPCClient(...params: ConstructorParameters<typeof import("./iframe/client").RPCClient>): Promise<import("./iframe/client").RPCClient> {
    const { RPCClient } = await import("./iframe/client");
    return new RPCClient(...params);
}


export {
    Chart,
    ChartBlock,
    ChartRow,
    ChartSeat
} from "./chart";

