import type Shop from "./api";
import { Event } from "./event";
import { type WizardPageResult, type WizardPage } from "./connector";

/**
 * Show a page to the user using an iframe.
 */
interface Configuration {
    type: "configuration";
    iframeTarget: string;
}

type PromiseResult<T> = T extends Promise<infer U> ? U : never;

/**
 * Let's the user select a seat.
 */
interface SelectSeat {
    type: "seat";
    event: Event;
    prices: PromiseResult<ReturnType<Shop["getEventPrices"]>>,

    seats: {
        id: number,
        seat: number|null
        category: number|null;
        discount: number|null;
    }[];
}

interface Completed {
    type: "$done";
}


export type WizardStep = Configuration|SelectSeat|Completed; 


export class Wizard {
    private shop: Shop;
    private wizard: AsyncGenerator<WizardPage, void, WizardPageResult>;
    private _page: WizardStep;
    private _done: boolean;

    constructor(shop: Shop, wizard: AsyncGenerator<WizardPage, void, WizardPageResult>) {
        this.shop = shop;
        this.wizard = wizard;
        this._page = {type: "configuration", iframeTarget: "about:blank"};
        this._done = false;
    }

    async __next(next: WizardPageResult|undefined): Promise<WizardStep> {
        const {done, value} = await this.wizard.next(next);
        if (done) { this._done = true; this._page = {type: "$done"}; return; }
        this._page = await this.__convertPage(value as WizardPage);
    }

    async next(next: WizardPageResult): Promise<WizardStep> {
        await this.__next(next);
        return this._page;
    }

    private async __convertPage(page: WizardPage): Promise<WizardStep> {
        if (page.type === "configuration") {
            return {type: "configuration", iframeTarget: page.page, __internal: page.internal} as any;
        } else if (page.type === "seat") {
            const [event, prices] = await Promise.all([
                this.shop.getEvent(page.event),
                this.shop.getEventPrices(page.event),
            ]);
            return {
                type: "seat",
                event,
                prices,
                seats: page.what 
            }
        } else {
            throw new Error("Unknown page type...");
        }
    }

    get done() { return this._done; }
    get page() { return this._page; }
}
