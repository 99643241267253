import Shop from "../api";
import { Signal } from "../connector";


function noop() {}


export function combineUnsubscribers(...unsubscribers: (() => void)[]): () => void {
    let unsubscribe = () => {
        unsubscribe = noop;
        for (let unsubscriber of unsubscribers)
            unsubscriber();
    };
    return () => { unsubscribe(); }
 }


export function simple<T>(subscribe: (set: (v: T) => void) => (() => void)): Signal<T> {
    let currentSubscription: () => void = noop;
    const subscribers: Map<number, (v: T) => void> = new Map();

    let counter = 0;
    let lastValue: {value: T, set: true}|{set: false} = { set: false };

    function broadcast(value: T) {
        for (let subscriber of subscribers.values()) {
            subscriber(value);
        }
    }

    function target(value: T) {
        lastValue = { value: value, set: true };
        broadcast(value);
    }

    return {
        subscribe(subscriber: (value: T) => void): () => void {
            if (lastValue.set)
                broadcast(lastValue.value);
            
            const id = counter++;
            subscribers.set(id, subscriber);

            if (subscribers.size === 1) {
                currentSubscription = subscribe(target);
            }

            return () => {
                subscribers.delete(id);
                if (subscribers.size === 0) {
                    currentSubscription();
                    currentSubscription = noop;
                    lastValue = { set: false };
                }
            }
        }
    }
}


export function unchanging<T>(value: T): Signal<T> {
    return {
        subscribe(subscriber: (value: T) => void): () => void {
            subscriber(value);
            return noop;
        }
    }
}

export function defaultPromise<T>(loading: T, loader: () => PromiseLike<T>): Signal<T> {
    let p: PromiseLike<T>|null = null;
    let v: {done: boolean, value: T|undefined} = {done: false, value: undefined};

    return {
        subscribe(subscriber: (value: T) => void): () => void {
            if (v.done) {
                subscriber(v.value!);
                return noop;
            }

            let skip: boolean = false;

            if (p === null) {
                p = loader();
                p.then((res) => {
                    v = {done: true, value: res};
                }, (e) => Shop.current.onError(e));
            }

            subscriber(loading);
            p.then((res) => {
                if (!skip)
                    subscriber(res);
            });

            return () => { skip = true; }
        }
    }
}


export function derived<T, V>(base: Signal<T>, functor: (c: T) => V): Signal<V> {
    return simple((set) => {
        const unsubscribe = base.subscribe((v) => {
            set(functor(v));
        });
        return () => { unsubscribe(); }
    });
}

export function get<T>(signal: Signal<T>): Promise<T> {
    return new Promise((rs) => {
        let seen = false;

        let unsubscribe = () => {};
        unsubscribe = signal.subscribe((v) => {
            if (seen) return;
            seen = true;
            unsubscribe();
            rs(v);
        });
        // Case: The signal calls the callback immediately.
        if (seen) unsubscribe();
    });
}


export class SwitchBack<T, D=T> implements Signal<T|D> {
  private signal: Signal<T> | undefined;
  private subscribers: ((value: T|D) => void)[] = [];
  private currentSubscription: (() => void) | undefined;
  private parentSubscriptionCount: number = 0;
  private _defaultValue: D|undefined;

  constructor(defaultValue: D|undefined = undefined) {
    this._defaultValue = defaultValue;
  }

  get defaultValue(): D|undefined {
   return this._defaultValue;
  }

  set defaultValue(value: D|undefined) {
    this._defaultValue = value;
    if (!this.signal && value !== undefined)
        this.subscribers.forEach((subscriber) => subscriber(value));
  }

  setParent(signal: Signal<T>|undefined) {
    // Unsubscribe from the current parent signal, if any
    if (this.currentSubscription) {
        this.unsubscribeFromParentSignal();
    }

    // Set the new parent signal
    this.signal = signal;

    // Subscribe to the new parent signal if there are subscribers
    if (this.parentSubscriptionCount > 0) {
      this.subscribeToParentSignal();
    }
  }

  subscribe(subscriber: (value: T|D) => void): () => void {
    this.subscribers.push(subscriber);

    // Increment the parent subscription count
    this.parentSubscriptionCount++;

    // If the SwitchBack had no subscribers before, subscribe to the parent signal
    if (this.parentSubscriptionCount === 1) {
      this.subscribeToParentSignal();
    } else if (!this.signal && this._defaultValue !== undefined) {
        // Send out the defaultValue if one is present,
        // but no parent subscription can be abused for sending it out.
        subscriber(this._defaultValue);
    }

    // Return a function to unsubscribe from the SwitchBack
    return () => {
      const index = this.subscribers.indexOf(subscriber);
      if (index !== -1) {
        this.subscribers.splice(index, 1);
      }

      // Decrement the parent subscription count
      this.parentSubscriptionCount--;

      // If the SwitchBack has no subscribers anymore, unsubscribe from the parent signal
      if (this.parentSubscriptionCount === 0) {
        this.unsubscribeFromParentSignal();
      }
    };
  }

  private subscribeToParentSignal() {
    if (this.signal) {
      this.currentSubscription = this.signal.subscribe((value: T) => {
        this.subscribers.forEach((subscriber) => subscriber(value));
      });
    } else if (this._defaultValue !== undefined) {
      this.subscribers.forEach((subscriber) => subscriber(this._defaultValue));
    }
  }

  private unsubscribeFromParentSignal() {
    if (this.currentSubscription) {
      this.currentSubscription();
      this.currentSubscription = undefined;
    }
  }
}
