import { sanitize, type Config } from 'dompurify';
import { readable, type Readable, type Writable } from 'svelte/store';
import { filter } from '@/utils/svelte/proxies';


export function sanitizedHtmlStore(parent: Writable<string>, config: Config): Writable<string> {
    return filter(parent, (value) => sanitize(value, {
        ...config,
        RETURN_DOM: false,
        RETURN_DOM_FRAGMENT: false,
        RETURN_TRUSTED_TYPE: false
    }));
}

export function onLoaded(cb: () => void): void {
    if (document.readyState !== "loading") {
        (window.requestIdleCallback||window.requestAnimationFrame)(cb);
    } else {
        document.addEventListener("readystatechange", () => {
            onLoaded(cb);
        }, {once: true});
    }
}


export const pixelRatio: Readable<number> = readable(window.devicePixelRatio, (set) => {
    let media: MediaQueryList|null = null;

    function listenOnceMore() {
        media = window.matchMedia(`screen and (resolution: ${window.devicePixelRatio}dppx)`);
        media.addEventListener("change", listenChange, {once: true});
    }
    function listenChange() {
        set(window.devicePixelRatio);
        listenOnceMore();
    }

    listenOnceMore();

    return () => {
        media?.removeEventListener("change", listenChange);
    }
})

export const mousePosition = readable({x: 0, y: 0}, (set) => {
    const listener = (event: MouseEvent) => set({x: event.pageX, y: event.pageY});
    document.body.addEventListener("mousemove", listener);
    return () => document.body.removeEventListener("mousemove", listener);
});


export function isNavigationInput(e: KeyboardEvent) {
    if (e.code === "F5") return true;
    if (e.code === "Shift" || e.code === "ShiftLeft" || e.code === "ShiftRight") return true;
    if (e.code === "Tab") return true;
    if (e.currentTarget !== e.target) {
        if (e.target instanceof HTMLElement) {
            console.log(e.target.nodeName);
            if (e.target.nodeName === "BUTTON") return true;
            if (e.target.nodeName === "A") return true;
            if (e.target.nodeName === "INPUT") return true;
            if (e.target.nodeName === "TEXTBOX") return true;
        }
    }
    return false;
}
