import moduleRules from "./module-rules.txt?raw";
import loader from "../platform/modules";
import { exportModuleRaw } from "../utils/pages";

function parseModuleRules(rules: string): Record<string, {rule: "hook"|"alias", param: string}[]> {
    const result: Record<string, {rule: "hook"|"alias", param: string}[]> = {};
    for (let line of rules.split("\n")) {
        const [file, rawRule] = line.split("://!!", 2);
        if (!rawRule) continue;
        const [ruleName, ...params] = rawRule.split(" ", 2)
        const param = params.join(" ");
        (result[file] ||= []).push({rule: ruleName as "hook"|"alias", param});
    }
    return result;
}

const rules = parseModuleRules(moduleRules);

export function declareAllModules(prefix: string, keys: string[], requireAsync: (name: string) => Promise<any>) {
    const waiter = [];
    for (let key of keys) {
        if ((key.indexOf(".cjs.js") >= 0) || (key.indexOf(".vue") >= 0)) {
            const strippedKey = prefix + key.substring(1)
            const ruleKey = "." + strippedKey;
            const backendKey = "backend" + prefix + strippedKey;
            exportModuleRaw(backendKey, ((k) => () => requireAsync(k))(key));

            for (let {rule, param} of (rules[ruleKey]||[])) {
                if (rule === "hook") {
                    loader.hook(param, backendKey)
                } else if (rule === "alias") {
                    if (process.env.NODE_ENV === 'development') {
                        console.debug(`Aliasing ${key} as ${param}`);
                    }
                    loader.modules[param] = loader.modules[backendKey];
                } else {
                    throw new Error(`Unknown hook rule ${rule} with param "${param}"`);
                }
            }
        } else {
            if (process.env.NODE_ENV === 'development') {
                console.debug(`Running inline: ${key.split("/").pop()}`)
            }
            waiter.push(requireAsync(key));
        }
    }
    Promise.all(waiter).catch(window.reportError);
}

export function runAllModulesInline(keys: string[], requireString: (name: string) => string) {
    const browserEval = (text: string) => (1, eval)(text);
    keys.sort((a, b) => a.length - b.length);
    keys.sort((a, b) => b.split("/").length - a.split("/").length);
    const contents = keys.map(f => [f, requireString(f)]);

    for (let [file, source] of contents) {
        if (process.env.NODE_ENV === 'development') {
            console.debug(`Running in window environment: ${file}`);
        }

        try {
            browserEval(source);
        } catch (e) {
            console.error(`Execution of ${file} failed with error:`);
            window.reportError(e);
        }
    }
}
